import styled from 'styled-components'

export const Wrapper = styled.div`
  z-index: 6000;
  position: relative;
  margin: 0;

  > a > img {
    width: ${({ size }) => (size ? size : '50px')};
  }

  ${({ theme }) => theme.media.s} {
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
  }

  ${({ theme }) => theme.media.xl} {
  }

  ${({ theme }) => theme.media.xxl} {
  }
`
