import styled, { css } from 'styled-components'

export const BgWrap = styled.section`
  position: relative;
  background-color: #f3f5f9;
`

export const Wrapper = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 100px 0;
  width: 100%;

  ${({ theme }) => theme.media.m} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2fr 1fr;
    grid-column-gap: 100px;
    grid-row-gap: 60px;

    > div:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    > div:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    > div:nth-child(3) {
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  ${({ theme }) => theme.media.l} {
  }
`

export const Content = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 0;
  ${({ center }) => center && 'text-align: center;'}

  h2 {
    margin: 0 0 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.25;
  }

  h3 {
    margin: 0 0 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
  }

  iframe {
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 950px;
    border: 0;
    ${({ theme }) => theme.boxShadows.level3}
  }

  & + & {
    padding-bottom: 80px;
  }
`
