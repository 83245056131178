import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 2400px;
  padding: 0 ${({ theme }) => theme.paddings.xs};

  ${({ theme }) => theme.media.s} {
    padding: 0 ${({ theme }) => theme.paddings.s};
  }

  ${({ theme }) => theme.media.m} {
    padding: 0 ${({ theme }) => theme.paddings.m};
  }

  ${({ theme }) => theme.media.l} {
    padding: 0 ${({ theme }) => theme.paddings.l};
  }

  ${({ theme }) => theme.media.xl} {
    padding: 0 ${({ theme }) => theme.paddings.xl};
  }

  ${({ theme }) => theme.media.xxl} {
    padding: 0 ${({ theme }) => theme.paddings.xxl};
  }
`
