import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import '@src/newWebsite/layout/theme/sanitize.css'
import { mainTheme } from '@src/newWebsite/layout/theme'

import Navigation from '@src/components/Navigation/Navigation'
import NavigationAside from '@src/components/NavigationAside/NavigationAside'
import HeaderWithText from '@src/components/HeaderWithText/HeaderWithText'
import Header from '@newWebsite/layout/organisms/Header'
import Footer from '@newWebsite/layout/organisms/Footer'

import { navItems } from '@src/constants/navItems'

const GlobalStyle = createGlobalStyle`
  @import url("https://use.typekit.net/hhn8tbu.css");

  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black2};
    font-family: dicsans, sans-serif;
    font-weight: normal;
  }

  #portal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9000;
  }
`

const StyledMain = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.margins.maxWidth};

  @media (min-width: ${({ theme }) => theme.media.s}) {
    flex-direction: row;
    padding: 0;
  }
`

const StyledMainSection = styled.section`
  order: 2;
  width: 100%;
  padding: 50px ${({ theme }) => theme.margins.phonePadding};

  @media (min-width: ${({ theme }) => theme.media.s}) {
    order: 1;
    width: 70%;
    ${({ homePage }) => homePage && 'margin-top: 80px'};
    padding: 120px 50px 50px ${({ theme }) => theme.margins.tabletPadding};
  }

  @media (min-width: ${({ theme }) => theme.media.l}) {
    width: 65%;
    padding: 130px 100px 60px ${({ theme }) => theme.margins.desktopPadding};
  }

  @media (min-width: ${({ theme }) => theme.media.tv}) {
    width: 60%;
    padding: 140px 150px 70px ${({ theme }) => theme.margins.tvPadding};
  }
`

const StyledAsideSection = styled.aside`
  z-index: 100;
  position: relative;
  order: 1;
  width: 100%;
  height: calc(20vh + 90px);

  @media (min-width: ${({ theme }) => theme.media.s}) {
    order: 2;
    width: 30%;
    height: auto;
  }

  @media (min-width: ${({ theme }) => theme.media.l}) {
    width: 35%;
  }

  @media (min-width: ${({ theme }) => theme.media.tv}) {
    width: 40%;
  }
`

const NewLayout = ({
  header,
  headerBold,
  homePage,
  children,
  asideContent,
  darkText,
}) => {
  const [contentActive, setContentActive] = useState(false)

  useEffect(() => {
    setTimeout(() => setContentActive(true), 900)
  })

  return (
    <ThemeProvider theme={mainTheme}>
      <>
        <GlobalStyle />
        <Header navItems={navItems} />
        <StyledMain>{children}</StyledMain>
        <Footer />
      </>
    </ThemeProvider>
  )
}

NewLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  asideContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

export default NewLayout
