import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { Link, graphql } from 'gatsby'

import HorWrap from '@atoms/HorWrap'

import { Wrapper, Text, Content, Box, Icon } from './styles.js'

const Offer = ({ items }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper>
        <HorWrap>
          <Text>
            <h2>Sprawdź zakres usług naszej kliniki</h2>
          </Text>
          <Content>
            {items.map((item, index) =>
              item.image ? (
                <Box
                  key={item.id}
                  index={index}
                  active={sectionInView}
                  bgImage={item.image}
                ></Box>
              ) : (
                <Box key={item.id} index={index} active={sectionInView}>
                  <Icon src={item.icon} />
                  <h2>{item.header}</h2>
                  <p>{item.desc}</p>
                  <Link to={`/uslugi/${item.slug}`} small>
                    Zobacz więcej
                  </Link>
                </Box>
              )
            )}
          </Content>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

Offer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Offer.defaultProps = {
  children: null,
}

export default Offer
