import { graphql } from 'gatsby'
import React from 'react'

import Layout from '@newWebsite/layout'
import SEO from '@src/components/SEO'

import Button from '@atoms/Button'
import Hero from '@organisms/Hero'
import Offer from '@organisms/Offer'
import Video from '@organisms/Video'
import CTA from '../newWebsite/layout/molecules/CTA'
import Map from '../newWebsite/layout/molecules/Map'

import { uslugiItems } from './../constants/uslugiItems'

const NewIndexPage = ({ data }) => (
  <Layout homePage>
    <SEO title="Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <Hero />
    <Video />
    <Offer items={uslugiItems} />
    <CTA>
      <h2>Chcesz zadbać o zdrowie jamy ustnej?</h2>
      <p>
        Skorzystaj z naszych usług i rozpocznij podróż do pięknego uśmiechu już
        dziś! Umów się na wizytę i daj nam szansę zadbać o zdrowie Twoich zębów.
        Czekamy na Ciebie z uśmiechem!
      </p>
      <Button to="/cennik" primary>
        Sprawdź cennik
      </Button>
    </CTA>
    <Map lat={52.10841} lng={20.7997} zoom={11} />
  </Layout>
)

export default NewIndexPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
