import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

import { Wrapper } from './styles.js'
import LogoImg from '@images/apple-touch-icon.png'

const Logo = ({ size }) => {
  return (
    <Wrapper size={size}>
      <TransitionLink
        to="/"
        title="Strona Główna"
        exit={{
          length: 1.2,
        }}
        entry={{
          delay: 1.2,
        }}
        data-text="Glossy Dental"
      >
        <img src={LogoImg} alt="Glossy Dental Logo" />
      </TransitionLink>
    </Wrapper>
  )
}

export default Logo
