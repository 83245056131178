import React from 'react'
import PropTypes from 'prop-types'

import HorWrap from '@atoms/HorWrap'

import { Wrapper } from './styles.js'

const CTA = ({ children }) => {
  return (
    <Wrapper>
      <HorWrap>{children}</HorWrap>
    </Wrapper>
  )
}

CTA.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

CTA.defaultProps = {
  children: null,
}

export default CTA
