import React from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { Wrapper, Marker, Icon } from './styles.js'
import IconLocation from '@assets/svg/icon-location-24.svg'

const Map = ({ lat, lng, zoom, children }) => {
  const createMapOptions = () => ({
    styles: [
      {
        stylers: [
          {
            hue: '#ea8a57',
          },
          {
            saturation: 50,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 50,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  })
  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyA6TA2fS8Yoq2RJvHCiaj4odl8kw_uhFHo',
        }}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={zoom}
        options={createMapOptions}
      >
        <Marker lat={lat} lng={lng}>
          <Icon src={IconLocation} />
        </Marker>
      </GoogleMapReact>
    </Wrapper>
  )
}

export default Map
