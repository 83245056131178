export const colors = {
  white: '#ffffff',
  white2: '#fafafa',
  white3: '#f5f5f5',

  black: '#000000',
  black2: '#000500',
  black3: '#1c1c1c',

  gray: '#6f6e70',
  gray2: '',
  gray3: '',

  primary: '#e86e31',
  primary2: '#f2e3bc',
  primary3: '',

  secondary: '',
  secondary2: '',
  secondary3: '',
}
