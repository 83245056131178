import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import {
  Wrapper,
  Content,
  Hamburger,
  MenuIcon,
  Menu,
  NavList,
  NavListItem,
} from './styles.js'
import IconMenu from '@assets/svg/menu.svg'

import Button from '@atoms/Button'
import Logo from '@atoms/Logo'

const Header = ({ active, navItems }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [scrollOnTop, setScrollOnTop] = useState(true)
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    let distanceFromTop = window.pageYOffset

    return function() {
      let newDistanceFromTop = window.pageYOffset
      if (newDistanceFromTop < 50 || distanceFromTop >= newDistanceFromTop) {
        setShowMenu(true)
      } else {
        setShowMenu(false)
      }
      if (newDistanceFromTop < 50) {
        setScrollOnTop(true)
      } else {
        setScrollOnTop(false)
      }
      distanceFromTop = newDistanceFromTop
    }
  }

  useEffect(() => {
    setTimeout(() => setShowMenu(true), 500)
    window.addEventListener('scroll', toggleMenu())
    return window.removeEventListener('scroll', toggleMenu())
  }, [])

  useEffect(() => {
    menuOpened
      ? (document.querySelector('html').style.overflowY = 'hidden')
      : (document.querySelector('html').style.overflowY = 'visible')
  }, [menuOpened])

  const handleMenuClick = () => {
    setMenuOpened(false)
    !scrollOnTop && setShowMenu(false)
  }

  return (
    <Wrapper active={active} scrolled={!scrollOnTop} menuShowed={showMenu}>
      <Content>
        <Logo />
        <Hamburger
          isOpened={menuOpened}
          onMouseDown={() => setMenuOpened(!menuOpened)}
        >
          <MenuIcon src={IconMenu} />
        </Hamburger>
        <Menu isOpened={menuOpened}>
          <NavList isOpened={menuOpened} onClick={handleMenuClick}>
            {navItems.map(item => (
              <NavListItem isOpened={menuOpened} key={item.title}>
                <TransitionLink
                  to={item.to}
                  title={item.title}
                  exit={{
                    length: 1.2,
                  }}
                  entry={{
                    delay: 1.2,
                  }}
                  data-text={item.title}
                >
                  <span>{item.title}</span>
                </TransitionLink>
              </NavListItem>
            ))}
            <TransitionLink
              to="wizyta"
              title="Wizyta"
              exit={{
                length: 1.2,
              }}
              entry={{
                delay: 1.2,
              }}
              data-text="Wizyta"
            >
              <Button ghost={true}>Umów wizytę</Button>
            </TransitionLink>
          </NavList>
        </Menu>
      </Content>
    </Wrapper>
  )
}

Header.propTypes = {
  active: PropTypes.bool,
  navItems: PropTypes.arrayOf(PropTypes.object),
}

Header.defaultProps = {
  active: false,
  navItems: [],
}

export default Header
