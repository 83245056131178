import styled, { css } from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.header`
  z-index: 7000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 2400px;
  transition: transform 0.6s ease;
  will-change: transform;
  transform: translate3d(
    0,
    ${({ menuShowed }) => (menuShowed ? '0' : '-100%')},
    0
  );

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.white};
    transition: opacity 0.3s ease;
    opacity: ${({ scrolled }) => (scrolled ? '1' : '0')};
  }

  ${({ theme }) => theme.media.xxl} {
    left: 50%;
    transform: translate3d(
      -50%,
      ${({ menuShowed }) => (menuShowed ? '0' : '-100%')},
      0
    );
  }
`

export const Content = styled.div`
  z-index: 6000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px ${({ theme }) => theme.paddings.xs};

  > a {
    z-index: 6000;
    position: relative;
  }

  ${({ theme }) => theme.media.s} {
    padding: 10px ${({ theme }) => theme.paddings.s};
  }

  ${({ theme }) => theme.media.m} {
    padding: 15px ${({ theme }) => theme.paddings.m};
  }

  ${({ theme }) => theme.media.l} {
    padding: 20px ${({ theme }) => theme.paddings.l};
  }

  ${({ theme }) => theme.media.xl} {
    padding: 25px ${({ theme }) => theme.paddings.xl};
  }

  ${({ theme }) => theme.media.xxl} {
  }
`

export const Hamburger = styled.div`
  z-index: 6000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;

  p {
    display: none;
  }

  &::before {
    content: '';
  }

  .line {
    fill: none;
    stroke: ${({ theme, isOpened }) =>
      isOpened ? theme.colors.white : theme.colors.primary};
    stroke-width: 6;
    transition: stroke 0.3s ease,
      stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    &2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }
    &3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      .line {
        &1 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
        &2 {
          stroke-dasharray: 1 60;
          stroke-dashoffset: -30;
          stroke-width: 6;
        }
        &3 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
      }
    `}

  ${({ theme }) => theme.media.m} {
    display: none;
  }
`

export const MenuIcon = styled(SVG)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.media.s} {
    width: 45px;
    height: 45px;
  }

  ${({ theme }) => theme.media.m} {
    width: 50px;
    height: 50px;
  }

  ${({ theme }) => theme.media.l} {
    width: 55px;
    height: 55px;
  }

  ${({ theme }) => theme.media.xl} {
    width: 60px;
    height: 60px;
  }

  ${({ theme }) => theme.media.xxl} {
    width: 65px;
    height: 65px;
  }
`

export const Menu = styled.nav`
  z-index: 5000;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100vw;
  height: ${({ isOpened }) => (isOpened ? '100vh' : '0')};
  background-color: ${({ theme }) => theme.colors.primary};
  transition: height 0.4s ease;
  will-change: height;

  ${({ theme }) => theme.media.m} {
    position: relative;
    width: auto;
    height: auto;
    background: none;
  }
`

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 30px 30px 120px;

  > a:last-child {
    display: none;
  }

  ${({ theme }) => theme.media.s} {
    padding: 30px 30px 160px;
  }

  ${({ theme }) => theme.media.m} {
    flex-direction: row;
    align-items: center;
    padding: 0;

    > a:last-child {
      display: inline-block;
      margin-left: 20px;
    }
  }

  ${({ theme }) => theme.media.l} {
  }
`

export const NavListItem = styled.li`
  transition: 0.3s ease;
  ${({ isOpened }) =>
    isOpened
      ? css`
          opacity: 1;
          transform: translate3d(0, 0, 0);
        `
      : css`
          opacity: 0;
          transform: translate3d(0, 20px, 0);
        `}

  &:nth-child(1) {
    transition-delay: 0.5s;
  }

  &:nth-child(2) {
    transition-delay: 0.6s;
  }

  &:nth-child(3) {
    transition-delay: 0.7s;
  }

  &:nth-child(4) {
    transition-delay: 0.8s;
  }

  &:nth-child(5) {
    transition-delay: 0.9s;
  }

  &:nth-child(6) {
    transition-delay: 1s;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    ${({ isOpened }) => !isOpened && 'transition-delay: 0s;'}
  }

  > a {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    color: ${({ theme }) => theme.colors.white};
    transition: 0.3s ease;
    cursor: pointer;
    ${({ isOpened }) =>
      isOpened
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 20px, 0);
          `}

    &::after {
      overflow: hidden;
      content: attr(data-text);
      position: absolute;
      top: o;
      left: 0;
      width: 100%;
      height: 0;
      padding: 0 15px;
      color: ${({ theme }) => theme.colors.black};
      transition: height 0.6s ease;
    }

    &:hover {
      &::after {
        height: 100%;
      }
    }
  }

  ${({ theme }) => theme.media.s} {
    > a {
      font-size: 35px;
    }
  }

  ${({ theme }) => theme.media.m} {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    > a {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.colors.primary};
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  ${({ theme }) => theme.media.l} {
  }

  ${({ theme }) => theme.media.xl} {
  }

  ${({ theme }) => theme.media.xxl} {
  }
`
