import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Link } from 'gatsby'

import HorWrap from '@atoms/HorWrap'
import Logo from '@atoms/Logo'

import { uslugiItems } from '@src/constants/uslugiItems'
import { generalInfo } from '@src/constants/generalInfo'

import VisaIcon from '@src/assets/svg/payments/visa.svg'
import MasterCardIcon from '@src/assets/svg/payments/mastercard.svg'
import MaestroIcon from '@src/assets/svg/payments/maestro.svg'

import { Wrapper, Items, Item, CardIcon, Social } from './styles.js'

const Footer = () => {
  return (
    <Wrapper>
      <HorWrap>
        <Items>
          <div>
            <Logo size="100px" />
          </div>
          <div>
            <h4>Kontakt</h4>
            <p>
              Glossy Dental
              <br /> Gabinet Stomatologiczny
            </p>
            <p>
              ul. Jaworowa 94
              <br />
              05-830 Nadarzyn
              <br /> mazowieckie
            </p>
            <p>
              501 530 196
              <br />
              570 180 857
              <br />
              stomatologia@glossydental.pl
            </p>
            <p>
              {generalInfo.socials.map(item => (
                <Social href={item.url} target="_blank" key={item.nick}>
                  <SVG src={item.icon} /> {item.nick}
                </Social>
              ))}
            </p>
          </div>
          <div>
            <h4>Usługi</h4>
            <ul>
              {uslugiItems.map(item => (
                <li key={item.id}>
                  <Link to={`/uslugi/${item.slug}`}>{item.header}</Link>
                </li>
              ))}
            </ul>
          </div>
          <Item>
            <div>
              <h4>Akceptujemy płatności kartą</h4>
              <CardIcon src={VisaIcon} />
              <CardIcon src={MasterCardIcon} />
              <CardIcon src={MaestroIcon} />
            </div>
            <div>
              <h5>&copy; 2023</h5>
            </div>
          </Item>
        </Items>
      </HorWrap>
    </Wrapper>
  )
}

Footer.propTypes = {}

export default Footer
