import styled, { css } from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.footer`
  background: ${({ theme }) => theme.colors.black3};
  color: ${({ theme }) => theme.colors.white};

  h4 {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 0.7;
  }

  h5 {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 0.7;
  }
`

export const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 50px;
  padding: 30px 0;
  font-size: 14px;
  font-weight: 400;

  p {
    margin: 0 0 10px;
    opacity: 0.5;
  }

  li {
    margin: 0 0 5px;
    opacity: 0.5;
    transition: opacity 0.2s ease;
    :hover {
      opacity: 0.7;
    }
  }

  svg path {
    fill: #ffffff;
  }

  ${({ theme }) => theme.media.m} {
    grid-template-columns: 1fr 2fr 2fr 2fr;
    padding: 50px 0;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;

  > div:last-child {
    opacity: 0.4;
  }
`

export const CardIcon = styled(SVG)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 37px;
  margin-right: 5px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const Social = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 30px;
    margin-right: 5px;
  }
`
