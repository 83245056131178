import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { graphql } from 'gatsby'
import SVG from 'react-inlinesvg'

import Button from '@atoms/Button'
import HorWrap from '@atoms/HorWrap'

import { BgWrap, Wrapper, Content } from './styles.js'

import VideoMp4 from '@assets/video.mp4'

const Video = ({ data }) => {
  const [sectionInView, setSectionInView] = useState(false)
  // const postsData = data.allInstaNode

  // console.log(postsData)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <BgWrap>
        <HorWrap>
          <Wrapper active={sectionInView}>
            <Content>
              <h2>Clear Aligner - Dyskretna Droga do Prostszego Uśmiechu</h2>
              <h3>
                Clear Aligner to innowacyjna metoda korekcji uśmiechu, która
                pozwala osiągnąć piękne i proste zęby w sposób dyskretny i
                komfortowy. Zamiast tradycyjnych aparatów stałych, clear aligner
                wykorzystuje przezroczyste nakładki, które stopniowo przesuwają
                zęby do idealnej pozycji. To idealne rozwiązanie dla tych,
                którzy pragną poprawić ułożenie swoich zębów, unikając przy tym
                widocznych elementów ortodontycznych.
              </h3>
              <h2>Jak działa Clear Aligner?</h2>
              <h3>
                Każda nakładka Clear Aligner jest precyzyjnie dostosowana do
                Twoich potrzeb. Na początku otrzymasz serię nakładek, z których
                każda reprezentuje kolejny etap korekcji. Co kilka tygodni
                przechodzisz do następnej nakładki, stopniowo przesuwając zęby
                do żądanej pozycji. Co więcej, clear aligner można łatwo
                zdejmować podczas jedzenia czy higieny jamy ustnej, co znacznie
                ułatwia codzienne funkcjonowanie. Zacznij swoją przygodę ku
                nowemu uśmiechowi już dziś - wybierz Clear Aligner!
              </h3>
            </Content>
            <Content center>
              <iframe
                src={`https://player.vimeo.com/video/858921850?autoplay=1&loop=1&autopause=0&background=1&transparent=0&muted=1`}
                title="TItle"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </Content>
            <Content center>
              <h2>
                Gotowy na transformację swojego uśmiechu dzięki ClearAligner?
              </h2>
              <h3>
                Rozpocznij podróż ku pięknym, prostym zębom już dziś! Skorzystaj
                z naszych usług i umów się na wizytę konsultacyjną.
              </h3>
              <Button
                to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new"
                primary
              >
                Umów wizytę
              </Button>
            </Content>
          </Wrapper>
        </HorWrap>
      </BgWrap>
    </InView>
  )
}

Video.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Video.defaultProps = {
  children: null,
}

// export const query = graphql`
//   query MyQuery {
//     allInstaNode {
//       edges {
//         node {
//           id
//           caption
//           likes
//           timestamp
//           mediaType
//           preview
//         }
//       }
//     }
//   }
// `

export default Video
