import styled from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;

  ${({ theme }) => theme.media.l} {
    height: 600px;
  }
`

export const Marker = styled.div`
  position: absolute;
  z-index: 1000;
  width: 10px;
  height: 10px;
`

export const Icon = styled(SVG)`
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${({ theme }) => theme.colors.mayaBlue};
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.mayaBlue} !important;
  }
`
