import styled, { css } from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const Text = styled.div`
  text-align: center;

  h2 {
    margin: 0 0 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.25;
  }
`

export const Content = styled.div`
  z-index: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 24px 0 0;
  text-align: center;

  ${({ theme }) => theme.media.m} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.media.l} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  ${({ bgImage }) =>
    bgImage && `background: center center url(${bgImage}) no-repeat;`}
  background-size: cover;
  padding: 50px 40px;

  h2 {
    margin: 0 0 10px;
    max-width: 400px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
  }

  h3 {
    margin: 0 0 40px;
    max-width: 400px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.33;
  }

  p {
    margin: 0 0 20px;
    max-width: 400px;
    line-height: 1.25;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: height 0.8s ease;
    transition-delay: ${({ index }) => (index + 5) * 0.2}s;
    ${({ active }) =>
      active
        ? css`
            height: 0;
          `
        : css`
            height: 100%;
          `}
  }

  ${({ theme }) => theme.media.m} {
    border: 1px solid rgba(0, 0, 0, 0.1);
    aspect-ratio: 1 / 1;
  }
`

export const Icon = styled(SVG)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 0 0 20px;
  svg {
    width: 100%;
    height: 100%;
  }
`
