import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledWrapper = styled.button`
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ ghost, theme }) => ghost && `border: 1px solid ${theme.colors.primary};`}
  border-radius: 4px;
  padding: ${({ small }) => (small ? '10px 20px' : '15px 30px')};
  background: ${({ theme, ghost }) => (ghost ? 'none' : theme.colors.primary)};
  color: ${({ theme, ghost }) =>
    ghost ? theme.colors.primary : theme.colors.white};
  font-size: ${({ small }) => (small ? '14px' : '18px')};
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  cursor: pointer;

  :after {
    z-index: 0;
    scontent: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: ${({ theme }) => theme.colors.primary2};
    transition: height 0.3s ease;
  }

  :hover:after {
    height: 100%;
  }

  + * {
    margin-left: 23px;
  }
`

const StyledContent = styled.span`
  z-index: 2;
  position: relative;
  display: block;
  text-align: center;
  text-transform: uppercase;
`

const Button = ({ to, white, small, ghost, children, onClickEvent }) => {
  const handleClick = () => {
    onClickEvent && onClickEvent()
  }

  return (
    <StyledWrapper
      onClick={() => handleClick()}
      white={white}
      small={small}
      ghost={ghost}
      as={to && Link}
      to={to && to}
    >
      <StyledContent>{children}</StyledContent>
    </StyledWrapper>
  )
}

Button.propTypes = {
  white: PropTypes.bool,
  small: PropTypes.bool,
  ghost: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

export default Button
