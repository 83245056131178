import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 100px 0;
  text-align: center;

  h2 {
    margin: 0 auto 10px;
    max-width: 700px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.33;
  }

  p {
    margin: 0 auto 20px;
    max-width: 700px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
  }
`
