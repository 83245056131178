import { colors } from './colors'

export const mainTheme = {
  colors,
  media: {
    xs: '@media (min-width: 368px)',
    s: '@media (min-width: 768px)',
    m: '@media (min-width: 1024px)',
    l: '@media (min-width: 1280px)',
    xl: '@media (min-width: 1520px)',
    xxl: '@media (min-width: 1920px)',
    tablet: '760px',
    desktop: '1280px',
    tv: '2200px',
  },
  paddings: {
    xs: `20px`,
    s: `25px`,
    m: `45px`,
    l: `70px`,
    xl: `80px`,
    xxl: `90px`,
  },
  margins: {
    maxWidth: '2200px',
    phonePadding: `40px`,
    tabletPadding: `30px`,
    desktopPadding: `60px`,
    tvPadding: `80px`,
  },
  boxShadows: {
    level1: 'box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;',
    level2:
      'box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;',
    level3:
      'box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
  },
}
