import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import SVG from 'react-inlinesvg'

import Button from '@atoms/Button'
import HorWrap from '@atoms/HorWrap'

import { Wrapper, Content, Images, Dots, SceneCurtain } from './styles.js'

import Img1 from '@assets/images/hero/image1.png'
import Img2 from '@assets/images/hero/image2.png'
import Img3 from '@assets/images/hero/image3.png'

const Hero = ({ data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  const [autoplayBlocked, setAutoplayBlocked] = useState(false)
  const [activeItem, setActiveItem] = useState(1)
  const autoPlayRef = useRef()
  const dataLength = 2

  const setNextSection = () => {
    const current = activeItem
    setTimeout(
      () =>
        current === dataLength ? setActiveItem(1) : setActiveItem(current + 1),
      0
    )
  }

  useEffect(() => {
    autoPlayRef.current = setNextSection
  })

  useEffect(() => {
    const play = () => {
      !autoplayBlocked && autoPlayRef.current()
    }

    const interval = setInterval(play, 5000)
    return () => clearInterval(interval)
  }, [autoplayBlocked])

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0}
    >
      <HorWrap>
        <Wrapper active={sectionInView}>
          <Content active={activeItem}>
            <div>
              <h2>
                Dbamy o piękny i zdrowy uśmiech pacjenta.
                <br />
                Nie jesteśmy po to aby oceniać, ale po to aby leczyć.
              </h2>
              <h3>
                Nie musisz do nasz dzwonić
                <br />
                umów wizytę już dziś poprzez formularz
              </h3>
              <div>
                <Button to="/wizyta">Umów wizytę</Button>
              </div>
            </div>
            <div>
              <h2>
                Uśmiech bez kompromisów
                <br />
                dzięki technologii ClearAligner&trade;
              </h2>
              <h3>
                Przezroczyste aparaty ortodontyczne, które służą do
                <br />
                korygowania wad zgryzu i ustawienia zębów
              </h3>
              <div>
                <Button to="/uslugi" ghost={true}>
                  Clear Aligner
                </Button>
              </div>
            </div>
            {/* <div>
              <h2>
                Promieniuj z uśmiechem dzięki naszej klinice dentystycznej
              </h2>
              <h3>Twoja jasna droga do zdrowego i pięknego uśmiechu!</h3>
              <div>
                <Button to="/uslugi" ghost={true}>
                  Oferta
                </Button>
                <Button to="/wizyta">Umów wizytę</Button>
              </div>
            </div> */}
            <Dots active={activeItem}>
              <button onClick={() => setActiveItem(1)} />
              <button onClick={() => setActiveItem(2)} />
              {/* <button onClick={() => setActiveItem(3)} /> */}
            </Dots>
          </Content>
          <Images active={activeItem}>
            <div>
              <img src={Img1} />
            </div>
            <div>
              <img src={Img2} />
            </div>
            {/* <div>
              <img src={Img3} />
            </div> */}
          </Images>
        </Wrapper>
      </HorWrap>
    </InView>
  )
}

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Hero.defaultProps = {
  children: null,
}

export default Hero
