import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  display: grid;
  width: 100%;
  height: 800px;
  background-color: white;
  opacity: ${({ active }) => (active ? '1' : '0')};
  will-change: opacity;
  transition: opacity 0.8s 0.2s ease;

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    top: -5%;
    left: -10%;
    display: block;
    border-radius: 50%;
    width: 35vw;
    height: 35vw;
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.1;
    transform: translate3d(0, ${({ active }) => (active ? '0' : '-30%')}, 0);
    transition: transform 2.5s ease;
  }

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    right: 50%;
    top: 60%;
    display: block;
    margin: 0 -200px 0 0;
    border-radius: 200px;
    width: 400px;
    height: 1200px;
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.1;
    transform: translate3d(0, ${({ active }) => (active ? '0' : '30%')}, 0);
    transition: transform 2.5s ease;
  }

  ${({ theme }) => theme.media.m} {
    // aspect-ratio: 12 / 6;
    padding: 0 0 5%;
    height: 1000px;

    &::before {
      top: -20%;
    }

    &::after {
      top: 20%;
      right: 10%;
      margin: 0;
      width: 600px;
      height: 1400px;
      border-radius: 300px;
    }
  }

  ${({ theme }) => theme.media.l} {
    padding: 0 0 2%;
  }
`

export const Content = styled.div`
  z-index: 1;
  position: relative;
  margin: 140px 0 80px;
  padding: 0 0 300px;
  text-align: center;

  h2 {
    margin: 0 0 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.33;
  }

  h3 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.33;
  }

  > div > div {
    margin: 20px 0 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
    transition: opacity 0.6s ease, transform 0.6s ease;

    > div > a:nth-child(1) {
      margin-bottom: 18px;
    }

    > div > a:nth-child(2) {
      margin-left: 0;
    }
  }

  > div:nth-child(${({ active }) => active}) {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  ${({ theme }) => theme.media.m} {
    position: absolute;
    top: 50%;
    left: 0;
    width: 40%;
    margin: 0;
    text-align: left;
    transform: translate3d(0, -50%, 0);

    > div > div {
      > a:nth-child(1) {
        margin-bottom: 18px;
      }

      > a:nth-child(2) {
        margin-left: 24px;
      }
    }
  }
`

export const Images = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    opacity: 0;
    transform: translate3d(50%, 0, 0);
    transition: all 0.6s ease;
  }

  > div:nth-child(${({ active }) => active}) {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  > div > img {
    width: 100%;
    height: auto;
  }

  ${({ theme }) => theme.media.m} {
    top: 15%;
    right: 10%;
    bottom: auto;
    left: auto;
    width: 45%;
    height: 800px;

    > div {
      height: 800px;
    }

    > div > img {
      width: auto;
      height: 100%;
    }
  }
`

export const Dots = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  transform: translate3d(-50%, 0, 0);

  > button {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: width 0.6s ease;
  }

  > button:nth-child(${({ active }) => active}) {
    width: 18px;
  }
`

export const SceneCurtain = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ active }) => (active ? '100%' : '0')};
    transition: height 0.8s 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
  }

  > div:nth-child(1) {
    height: 100%;
    background: white;
    opacity: ${({ active }) => (active ? '0' : '1')};
    transition: opacity 0.8s 2s ease;
  }

  > div:nth-child(2) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 52%;
      background: white;
      transition: transform 0.8s 2.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      bottom: 50%;
      border-bottom: 2px solid black;
      transform: translate3d(0, ${({ active }) => (active ? '-100%' : '0')}, 0);
    }

    &::after {
      top: calc(50% - 3px);
      border-top: 2px solid black;
      transform: translate3d(0, ${({ active }) => (active ? '120%' : '0')}, 0);
    }
  }

  > div:nth-child(3) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -30px;
      width: 50%;
      background: white;
      transition: transform 0.8s 1.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      right: 50%;
      border-right: 2px solid black;
      transform: translate3d(${({ active }) => (active ? '-101%' : '0')}, 0, 0);
    }

    &::after {
      left: calc(50% - 3px);
      border-left: 2px solid black;
      transform: translate3d(${({ active }) => (active ? '103%' : '0')}, 0, 0);
    }
  }
`
